import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import GlueStatus from '../pages/GlueStatus';

const Content: React.FC = () => {
  return (
    <main className="content">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/glueStatus" element={<GlueStatus />} />
      </Routes>
    </main>
  );
};

export default Content;
