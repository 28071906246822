// src/components/JobStatus.tsx
import React from 'react';

interface JobStatusProps {
    jobName: string;
    status: string;
    color: string;
}

const JobStatus: React.FC<JobStatusProps> = ({ jobName, status, color }) => (
    <div style={{ backgroundColor: color, padding: '10px', margin: '5px', color: 'white' }}>
        <h3>{jobName}</h3>
        <p>Status: {status}</p>
    </div>
);

export default JobStatus;
