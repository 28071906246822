// src/components/GlueStatusDashboard.tsx
import React, { useEffect, useState } from 'react';
import JobStatus from './JobStatus';
import { fetchGlueJobStatuses, GlueJobStatus } from '../services/glueService';

const GlueStatusDashboard: React.FC = () => {
    const [statuses, setStatuses] = useState<GlueJobStatus[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchGlueJobStatuses();
            setStatuses(data);
        };

        fetchData();
    }, []);

    return (
        <div>
            <h1>AWS Glue Job Statuses</h1>
            <div>
                {statuses.map((status, index) => (
                    <JobStatus key={index} {...status} />
                ))}
            </div>
        </div>
    );
};

export default GlueStatusDashboard;
