import React from 'react';
import GlueStatusDashboard from '../components/GlueStatusDashboard';

const GlueStatus: React.FC = () => {
  return (
    <div className="container">
        <GlueStatusDashboard />
    </div>
  );
};

export default GlueStatus;
