// src/services/glueService.ts
const API_URL = 'https://3367vbz3ha5hdwies6vm3lp5my0rbyzp.lambda-url.eu-west-1.on.aws/';

export interface GlueJobStatus {
    jobName: string;
    status: string;
    color: string;
}

export const fetchGlueJobStatuses = async (): Promise<GlueJobStatus[]> => {
    const response = await fetch(API_URL);
    const data = await response.json();
    return data;
};